import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import kyleGlenn from '../../images/kyle-glenn.jpg';

interface StatusPageProps {
  statusCode: number;
  statusMessage: string;
  errorMessage: string;
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    borderRadius: '20px',
    border: '1px solid #e0e0e0',
  },
  margin: {
    marginTop: '20px',
    marginBottom: '20px',
  }
});

export default function StatusPage({ statusCode, statusMessage, errorMessage }: StatusPageProps) {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={9}>
        <Card className={classes.root}>
          <CardContent>
            <Typography variant="h1" align="center">{statusCode}</Typography>
            <Typography variant="h2" gutterBottom align="center" >
              {statusMessage}
            </Typography>
            <hr />
            <Grid container
              alignItems="center"
              justify="center"
            >
              <span className={classes.margin}>
                <img src={kyleGlenn} alt="by Kyle Glenn on Unsplash" />
              </span>
            </Grid>
            <Typography variant="h5" component="h2">
              {errorMessage}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}