import React from 'react';
import logo from '../../icons/RemoteCareLiveLogo.svg';

// When using this in other parts of the application, apply styling for size there.
// I have not included styling because of the very likely need to
// have different sizes for different applications of this logo.
export default function RemoteCareLiveLogo() {
  return (
    <div>
      <img src={logo} alt={"Remotecare Live logo"} />
    </div>
  );
}
